// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}
// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}
.regulations-container {
  @extend %out-box;
  padding-bottom: 1.6rem;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");
  &-bread {
    @extend %inside-box;
    padding-top: 0.78rem;

    font-size: 0.14rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #273143;
    margin-bottom: 0.28rem;
  }
  &-pic {
    width: 6.6rem;
    height: 1.25rem;
    position: absolute;
    top: 0;
    right: 3.6rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.tab-subSwitch {
  display: flex;
  align-items: center;
  margin-bottom: 0.28rem;

  &-title {
    font-size: 0.16rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #761f1e;
    line-height: 0.16rem;
    margin-right: 0.1rem;
  }

  &-choose-item {
    display: flex;

    li {
      padding: 0.08rem 0.34rem;
      font-size: 0.16rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #273143;
      background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li2.png")
        no-repeat;
      background-size: 100% 100%;
      margin-right: 0.3rem;
      cursor: pointer;
    }
    .imgType{
      background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
      no-repeat;
      background-size: 100% 100%;
    }
  }
}
// 被激活的类型样式
.tabActive {
  // background-color: #761F1E;
  background: url("https://image.bookgo.com.cn/webculture/jm/activity_type_li.png")
    no-repeat !important;
  background-size: 100% 100% !important;
  color: #fff !important;
}

.tab-data {
  font-size: 0.14rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #273143;
  line-height: 0.2rem;
  margin-bottom: 0.28rem;
  margin-top: 0.2rem;
}
